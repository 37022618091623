export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 264,
            //             phoneElementId: 272,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Fire Fighting Detection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName: '4.5kN',
            //                     sort: 4
            //                 }, {
            //                     descName: '80L/s',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Traction',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Flow Capacity',
            //                     sort: 9
            //                 }],
            //             type: 'video',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 265,
            //             phoneElementId: 273,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Fire Fighting Detection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'With fire fighting, detection and other functions, it has the characteristics of strong obstacle crossing ability and powerful traction. It integrates multiple cutting-edge technologies such as image acquisition, thermal imaging detection and environmental detection, and can replace firefighters to enter dangerous areas for information acquisition, fire control, fire extinguishing and other operations. It provides reliable decision-making support for combat decision-making, monitors various toxic gases in real time, and effectively guarantees the safety of firefighters.',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 266,
            //             phoneElementId: 274,
            //             descList: [
            //                 {
            //                     descName: 'Fire Fighting',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The equipped high-power motor can pull a fire water belt to dangerous fire source areas to put out the fire. It can remotely control spraying water or foam. It can spray water through directly or mist blossom dual forms. It can turn 270 ° horizontally and 120 ° pitch. The flow can reach 80L/s, the water range exceeds 80m, and the foam mixture range exceeds 65m.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 267,
            //             phoneElementId: 275,
            //             descList: [
            //                 {
            //                     descName: 'Fire Fighting Detection',
            //                     sort: 1
            //                 }, {
            //                     descName: 'With remote detection capability, robot can be remotely controlled to conduct on-site reconnaissance before entering hazardous areas. The robot is equipped with three types of environmental perception devices, including PTZ, HD camera, thermal imaging camera and gas sensors. It allows users to grasp environmental factors such as on-site images, videos, sound, temperature and humidity, and toxic and harmful gases without getting close to the scene. While reducing their own risk, it provides strong auxiliary support for quickly formulating various search and rescue measures. It can also be linked with sound and light alarm modules to warn firefighters or the public, effectively avoiding danger.',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 268,
            //             phoneElementId: 276,
            //             descList: [
            //                 {
            //                     descName: 'Environmental Temperature and Humidity Monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The robot monitors the temperature and humidity of the environment and transmits it in real-time to the remote control, which can remotely understand the temperature and humidity environment on site.',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 269,
            //             phoneElementId: 277,
            //             descList: [
            //                 {
            //                     descName: 'Disaster Site Temperature Monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The robot monitors the temperature and humidity of the environment and transmits it in real-time to the remote control, which can remotely understand the temperature and humidity environment on site.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 270,
            //             phoneElementId: 278,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Fire Fighting Detection Robot',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: 'Walking Speed',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: 'Max. Stride',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Traction',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: 'Water Flow Range (water)(foam mixture)',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: 'Curb Weight',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 9
            //                 }, {
            //                     descName: 'Gradeability',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: 'Wading Depth',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: 'Flow Capacity',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: 'Battery Endurance',
            //                     sort: 13
            //                 }, {
            //                     descName: '1490mm×870mm×1390mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIB T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s,Speed Adjustable',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '350mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '≥4.5kN',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: '≥80m（water）,≥65m（foam mixture）',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤780kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP66',
            //                     sort: 21
            //                 }, {
            //                     descName: '≥35°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '80L/s',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: 'Continuous water spraying time ≥ 5 h or continuous walking time (without pulling water belt) ≥ 2 h',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: 271,
            //             phoneElementId: 279,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Fire Fighting Detection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Intelligent detection, efficient fire fighting',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         }
            //     ],
            elmentList: [],
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        //    this.$store.dispatch("pageset/getSourceData",
        //             {
        //                 createBy: '',
        //                 updateBy: '',
        //                 elmentList: this.elmentList,
        //                 pageId: this.$route.query.pageId,//页面id
        //                 pageName: this.$route.path,
        //                 path: this.$route.path,
        //                 remark: '',
        //             }
        //         );

        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                }
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}